//import Cleave from 'imports/cleave.js'
import siteCommon from 'site/site-common'
import overlay from "site/overlay";
//import flatpickr from "imports/flatpickr";

let adi = {
    init: function () {
        $('#login-btn').on('click', adi.login)
        $('#register-btn').on('click', adi.register)
        $('#tax-years-btn').on('click', adi.taxYearDownload)
        $('#reset-btn').on('click', function () {
            $('#reset-password-form').submit()
        })

        if (typeof adiId !== 'undefined' && typeof adiHash !== 'undefined' && adiId && adiHash) {
            adi.populateData()
        }


        if (site_segment1 == 'account-settings') {
            $('.update-profile-hook').on('click', adi.updateProfile)
            $('.update-password-hook').on('click', adi.updatePassword)
            $('.update-email-hook').on('click', adi.updateEmail)

            let params = (new URL(document.location)).searchParams;
            let editState = params.get("state");
            if (editState === 'email') {
                //email has been updated in Craft, now trigger ADI
                adi.updateEmailPart2Adi()
            }
        }


    },//init

    updateEmail: function (e) {
        e.preventDefault()

        //clear errors
        $('#top-bar-message').html('')
        $('.page-account-settings .row').removeClass('error')

        let email = $('#fieldid-email').val()
        let currentPassword = $('#fieldid-currentPassword').val()

        let cont = true
        if (!siteCommon.isValidEmail(email)) {
            cont = false
            $('#row-email').addClass('error')
        }
        if (!currentPassword) {
            cont = false
            $('#row-currentPassword').addClass('error')
        }
        1
        /*
        if (email != emailConfirm) {
            cont = false
            $('#row-emailConfirm').addClass('error')
        }
         */

        if (cont) {
            $('#email-update-form').submit();
        } else {
            siteCommon.scOpenTopOfPage()
        }
    },
    updateEmailPart2Adi: async function () {
        let dataArray = []
        dataArray.push({name: 'method', value: 'updateEmail'})
        dataArray.push({name: 'id', value: adiId})
        dataArray.push({name: 'h', value: adiHash})
        dataArray.push({name: 'email', value: emailTemp})
        dataArray.push({name: 'h2', value: emailTempHash})

        const result = await $.ajax({
            type: "POST",
            url: site_pd,
            data: dataArray,
            dataType: "json",
            complete: function (data) {
                adi.populateData()
            },
            error: function (e) {
                $('#top-bar-message').html('<p class="important">An error has occured.  Please try again later.</p>')
                siteCommon.scOpenTopOfPage()
                //window.location.href = "/account-settings/?state=error"
            }
        });
    },

    updatePassword: function (e) {
        e.preventDefault()

        //clear errors
        $('#top-bar-message').html('')
        $('.page-account-settings .row').removeClass('error')

        let newPassword = $('#fieldid-newPassword').val()

        let cont = true
        if (!siteCommon.isValidPassword(newPassword)) {
            cont = false
            $('#row-newPassword').addClass('error')
        }


        if (cont) {
            $('#password-update-form').submit();
        } else {
            siteCommon.scOpenTopOfPage()
        }
    },


    updateProfile: async function () {
        // what section are we in


        //clear errors
        $('#top-bar-message').html('')
        $('.page-account-settings .row').removeClass('error')


        //get values
        let title = $('#fieldid-title').val()
        let firstName = $('#fieldid-firstName').val()
        let lastName = $('#fieldid-lastName').val()
        let preferredName = $('#fieldid-preferredName').val()
        let birthday = $('#fieldid-birthday').val()
        let phone = $('#fieldid-phone').val()
        let address1 = $('#fieldid-address1').val()
        let address2 = $('#fieldid-address2').val()
        let city = $('#fieldid-city').val()
        let country = $('#fieldid-country').val()
        let state = $('#fieldid-state').val()
        let zip = $('#fieldid-zip').val()
        let email = $('#fieldid-email').val()

        //validate
        let cont = true
        if (address1 === '' || String(address1).length < 2) {
            $('#row-address1').addClass('error')
            cont = false
        }
        if (city === '' || String(city).length < 2) {
            $('#row-city').addClass('error')
            cont = false
        }
        if (country === '') {
            $('#row-city').addClass('error')
            cont = false
        }
        if (zip === '' || String(zip).length < 4) {
            $('#row-zip').addClass('error')
            cont = false
        }

        if (cont) {
            let dataArray = []
            dataArray.push({name: 'method', value: 'updateProfile'})
            dataArray.push({name: 'id', value: adiId})
            dataArray.push({name: 'h', value: adiHash})


            dataArray.push({name: 'title', value: title})
            dataArray.push({name: 'firstName', value: firstName})
            dataArray.push({name: 'lastName', value: lastName})
            dataArray.push({name: 'preferredName', value: preferredName})
            dataArray.push({name: 'birthday', value: birthday})
            dataArray.push({name: 'phone', value: phone})
            dataArray.push({name: 'address1', value: address1})
            dataArray.push({name: 'address2', value: address2})
            dataArray.push({name: 'city', value: city})
            dataArray.push({name: 'country', value: country})
            dataArray.push({name: 'state', value: state})
            dataArray.push({name: 'zip', value: zip})
            dataArray.push({name: 'email', value: email})
            const result = await $.ajax({
                type: "POST",
                url: site_pd,
                data: dataArray,
                dataType: "json",
                complete: function (data) {
                    let obj = JSON.parse(data.responseText)
                    window.location.href = "/account-settings/?state=success"
                },
                error: function (e) {
                    $('#top-bar-message').html('<p class="important">An error has occured.  Please try again later.</p>')
                    siteCommon.scOpenTopOfPage()
                    //window.location.href = "/account-settings/?state=error"
                }
            });
        } else {
            siteCommon.scOpenTopOfPage()
        }


    },

    populateData: async function () {
        let dataArray = []
        dataArray.push({name: 'method', value: 'getMasterInfo'})
        dataArray.push({name: 'id', value: adiId})
        dataArray.push({name: 'h', value: adiHash})
        dataArray.push({name: 'segment', value: site_segment1})

        const result = await $.ajax({
            type: "POST",
            url: site_pd,
            data: dataArray,
            dataType: "json",
            complete: function (data) {
                let obj = {}
                if (data && data.responseText) {
                    try {
                        obj = JSON.parse(data.responseText)
                    } catch (e) {
                    }

                }
                adi.populateDisplayAccountName(obj)
                switch (site_segment1) {
                    case "annual-statements":
                        let taxYears = obj.taxYears
                        if (!taxYears || taxYears.length === 0) {
                            $('#zone-form-tax').html("<p>We have no tax records for you on file</p>")
                        } else {
                            let temp = "<option value=''>Select a year to download a statement</option>"
                            for (let i = 0; i < taxYears.length; i++) {
                                temp += `<option value='${taxYears[i]}'>${taxYears[i]}</option>`
                            }
                            $('#field-statement').html(temp)
                        }
                        break;
                    case "dashboard":
                        let balance_freeplay = obj.balance_freeplay
                        let balance_pointplay = obj.balance_pointplay
                        let balance_treasurechest = obj.balance_treasurechest
                        if (balance_freeplay === '') {
                            balance_freeplay = '-'
                        }
                        if (balance_pointplay === '') {
                            balance_pointplay = '-'
                        }
                        if (balance_treasurechest === '') {
                            balance_treasurechest = '-'
                        }
                        $('#adi-freeplay').html(balance_freeplay)
                        $('#adi-pointplay').html(balance_pointplay)
                        $('#adi-treasurechest').html(balance_treasurechest)

                        //promotions HTML
                        const rightArrowSmall = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 108.3 199.8" style="enable-background:new 0 0 108.3 199.8;" xml:space="preserve">
<style type="text/css">.st0{fill:#FFFFFF;}</style>
<path class="st0" d="M102.5,94l-85-90.3C15.9,2,13.5,1,11.1,1c-2.2,0-4.3,0.8-6,2.4c-3.2,3.2-3.4,8.5-0.6,11.9l74,78.7c2.7,3.3,2.8,8,0.2,11.4l-0.1,0.2l-3.9,4.2L4.9,184c-3.1,3.4-2.9,9,0.5,12.3c1.6,1.5,3.7,2.3,5.9,2.3c2.3,0,4.6-1,6.3-2.7l85-90.3
l0.1-0.2C105.3,102,105.2,97.3,102.5,94z"/>
</svg>`
                        const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};
                        let promotionsHtmlFinal = ''

                        if (obj.promotions_xml && obj.promotions_xml.length) {
                            for (let i = 0; i < obj.promotions_xml.length; i++) {
                                let tobj = obj.promotions_xml[i]

                                let beginDate = new Date(tobj.BeginDate).toLocaleDateString('en-us', dateOptions)
                                let endDate = new Date(tobj.EndDate).toLocaleDateString('en-us', dateOptions)
                                let promotionId = 'TIEDW'
                                let promotionLink = "https://book.rguest.com/onecart/wbe/calendar/1293/treasureisland/" + promotionId
                                let PublicDescription = tobj.PublicDescription
                                if (typeof PublicDescription == 'object') {
                                    PublicDescription = ''
                                }
                                let promotionsHtml = `<div data-promo-id="${tobj.PromoID}" class="promotion rte sr-fade">
                                        <div class="graphic">
                                            <div class="img">
                                                <div class="bgimg" style="background-image: url(${imagePromotion});"></div>
                                            </div>
                                            <div class="titles">
                                                <div class="titles2">
                                                    <h5>${tobj.PromoName}</h5>
                                                    <p>Valid: ${beginDate} -  ${endDate}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="copy">
                                            <div class="desc">${PublicDescription}</div>
                                            <div class="btn-outer">
                                                <button data-promo-id="${tobj.PromoID}" class="btn overlay-hook" data-overlay="promotions" >
                                                    <span class="btn-txt">REQUEST RESERVATION</span>
                                                    <span class="btn-crt">${rightArrowSmall}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>`
                                promotionsHtmlFinal += promotionsHtml
                            }
                        } else {
                            promotionsHtmlFinal = `<div class="rte"><p>No promotions Found</p></div>`
                        }
                        /*
                        <button data-promo-id="123" class="btn overlay-hook" data-overlay="promotions" >
                                                    <span class="btn-txt">REQUEST RESERVATION</span>
                                                    <span class="btn-crt">${rightArrowSmall}</span>
                                                </button>
                         */

                        $('#promotions-grid-hook').html(promotionsHtmlFinal)
                        $('#promotions-grid-hook .overlay-hook').on('click', overlay.doOverlayHandle)

                        //dashboard overlay defaults
                        $(".fui-form-container input[name='fields[firstName]']").val(obj.FirstName)
                        $(".fui-form-container input[name='fields[lastName]']").val(obj.LastName)
                        $(".fui-form-container input[name='fields[guestEmail]']").val(obj.Email)
                        $(".fui-form-container input[name='fields[playerId]']").val(obj.player_id)


                        //slot play calculator
                        $('#points-form-btn-hook').on('click', adi.pointsFormCalculator)
                        $('#points-form-hook').on('keypress',function(e) {
                            if(e.which === 13) {
                                adi.pointsFormCalculator()
                            }
                        })


                        break;
                    case "account-settings":
                        $('#fieldid-title').val(obj.Title)
                        $('#fieldid-firstName').val(obj.FirstName)
                        $('#fieldid-lastName').val(obj.LastName)
                        $('#fieldid-preferredName').val(obj.PreferredName)
                        $('#fieldid-birthday').val(obj.Birthday)
                        $('#fieldid-phone').val(obj.PhoneNumber)
                        $('#fieldid-address1').val(obj.AddressLine1)
                        $('#fieldid-address2').val(obj.AddressLine2)
                        $('#fieldid-city').val(obj.City)
                        //$('#fieldid-country').val(obj.Country)
                        //$('#fieldid-state').val(obj.StateProvince)
                        $('#fieldid-zip').val(obj.PostalCode)
                        $('#fieldid-email').val(obj.Email)

                        $('#rofieldid-title').html(obj.Title)
                        $('#rofieldid-firstName').html(obj.FirstName)
                        $('#rofieldid-lastName').html(obj.LastName)
                        $('#rofieldid-preferredName').html(obj.PreferredName)
                        $('#rofieldid-birthday').html(obj.Birthday)
                        $('#rofieldid-phone').html(obj.PhoneNumber)
                        $('#rofieldid-address1').html(obj.AddressLine1)
                        $('#rofieldid-address2').html(obj.AddressLine2)
                        $('#rofieldid-city').html(obj.City)
                        //$('#rofieldid-country').html(obj.Country)
                        //$('#rofieldid-state').html(obj.StateProvince)
                        $('#rofieldid-zip').html(obj.PostalCode)
                        $('#rofieldid-email').html(obj.Email)


                        let thtml = ''
                        let roVal = ''
                        let selected = ''
                        for (let i = 0; i < dataCountries.length; i++) {
                            selected = ''
                            let item = dataCountries[i]
                            if (item.code === obj.Country) {
                                selected = "selected='selected'"
                                roVal = item.label
                            }
                            thtml += `<option value='${item.code}' ${selected}>${item.label}</option>`
                        }
                        $('#fieldid-country').html(thtml)
                        $('#rofieldid-country').html(roVal)


                        thtml = ''
                        roVal = ''
                        thtml += `<option value=''></option>`
                        for (let i = 0; i < dataStates.length; i++) {
                            selected = ''
                            let item = dataStates[i]
                            if (item.code === obj.StateProvince) {
                                selected = "selected='selected'"
                                roVal = item.label
                            }
                            thtml += `<option value='${item.code}' ${selected}>${item.label}</option>`
                        }
                        $('#fieldid-state').html(thtml)
                        $('#rofieldid-state').html(roVal)

                        break;

                }

            },
            error: function () {
                console.error('err')
            }
        });
    },

    populateDisplayAccountName(obj) {
        let thename = obj.PreferredName
        if (!thename) {
            thename = obj.FirstName
        }
        if (!thename) {
            thename = obj.player_id
        }
        $('.hook-preferred-name').html(thename)
    },

    login: async function (e) {
        e.preventDefault()
        adi.clearErrorMessage()

        let loginName = $('#loginName').val()
        loginName = adi.trimLeadingZeroes(loginName)
        let password = $('#password').val()
        let rememberMe = $('#field-rememberMe').is(":checked")
        let rememberMeVal = (rememberMe) ? "1" : ""

        let cont = true
        if (!loginName || !password) {
            cont = false
            adi.setErrorMessage('Please fill out all fields')
        }
        if (cont) {
            adi.setErrorMessage('')
            $('#final-password').val(password)
            $('#final-loginName').val(loginName)
            $('#final-rememberMe').val(rememberMeVal)
            $('#adi-form-login').submit()
        }
    },
    register: async function (e) {
        e.preventDefault()
        adi.clearErrorMessage()

        let loginName = $('#loginName').val()
        loginName = adi.trimLeadingZeroes(loginName)
        let month = $('#month').val()
        let day = $('#day').val()
        let year = $('#year').val()
        let password = $('#password').val()
        let dob = year + "-" + month + "-" + day

        let cont = true
        let errorMsg = ''
        $('#register-errors').html('')
        if (!loginName || !month || !day || !year) {
            errorMsg = 'Please fill out all the fields'
            cont = false
        }
        if (!siteCommon.isValidPassword(password)) {
            errorMsg = 'Password needs a minimum of eight characters, at least one letter, one number and one special character'
            cont = false
        }


        if (cont) {
            let dataArray = []
            dataArray.push({name: 'method', value: 'register'})
            dataArray.push({name: 'dob', value: dob})
            dataArray.push({name: 'loginName', value: loginName})
            const result = await $.ajax({
                type: "POST",
                url: site_pd,
                data: dataArray,
                dataType: "json",
                complete: function (data) {
                    let obj = JSON.parse(data.responseText)
                    if (obj.success) {
                        adi.setErrorMessage('Success')
                        $('#final-password').val(password)
                        $('#final-loginName').val(loginName)
                        $('#final-playersClubId').val(loginName)
                        $('#final-email').val(obj.email)
                        $('#adi-form-register').submit()
                    } else {
                        adi.setErrorMessage('Account with that ID and birthdate not found.')
                    }
                },
                error: function () {
                    adi.setErrorMessage('An unknown error has occurred.  Please try again later.')
                }
            });
        } else {
            $('#register-errors').html(`<p class="important">${errorMsg}</p>`)
        }

    },
    setErrorMessage(txt) {
        $('#error-zone').html("<p>" + txt + "</p>")
    },
    clearErrorMessage() {
        $('#error-zone').html('')
    },


    validateUserProfile: function () {
        var cont = true;

        $('.screenings-popup .signup-state-error').hide();
        if (screenings.clicked_submit_once) {
            //clear errors
            screenings.clearAllErrors();

            //check form
            var answers = screenings.getAnswersObject('rsvp');

            //fn
            if (answers.first_name === '') {
                cont = false;
                $('#selector-first-name').addClass('error');
            }
            //ls
            if (answers.last_name === '') {
                cont = false;
                $('#selector-last-name').addClass('error');
            }
            /*
            //phone
            if (answers.phone === '' || answers.phone.length<10 ) {
                cont=false;
                $('#selector-phone').addClass('error');
            }
            */

            //email
            if (answers.email === '' || stickCommon.isValidEmail(answers.email) === false) {
                cont = false;
                $('#selector-email').addClass('error');
            }
            //affiliation
            if (answers.affiliation === '') {
                cont = false;
                $('#selector-affiliation').addClass('error');
            }
            //num guests
            if (answers.friends === '') {
                //cont=false;
                //$('#selector-guests').addClass('error');
            }

            //legal
            /*
            if (answers.legal === "") {
                cont=false;
                $('#signup-legal').addClass('error');
            }
            */

            //make button grey
            if (cont === false) {
                $('.screenings-popup .button').addClass('error');
            }

        } else {
            cont = false;
        }
        return cont;

    },

    taxYearDownload: function (e) {
        e.preventDefault()

        $('#error-zone').html('')
        let msg = ''
        let year = $('#field-statement').val()
        let acceptTerms = $('#field-acceptTerms').is(":checked")
        let cont = true

        if (!year) {
            cont = false
            msg = '<p>Please select a year</p>'
        } else if (!acceptTerms) {
            cont = false
            msg = '<p>Please read and accept terms</p>'
        }
        if (cont) {
            $('#pdf-form').submit()
            return true
        } else {
            $('#error-zone').html(msg)
            return false
        }
    },
    pointsFormCalculator() {
        let thtml = 'Please enter valid points.'
        let val = Number($('#points-form-hook').val())
        if (val && val > 999999999) {
            $('#points-form-hook').val('')
            thtml = 'Please enter valid points.'
        } else if (val) {
            val = Math.floor(val / 20000) * 20000;
            let val2 = val / 400
            val = val.toLocaleString()
            val2 = val2.toLocaleString()
            thtml = `You have ${val} redeemable points worth $${val2} in FREE SLOT PLAY!`
            $('#points-form-results-msg').show();
        } else {
            $('#points-form-hook').val('')
        }
        $('#points-form-results').html(thtml);

    },
    trimLeadingZeroes(val) {
        val = String(val)
        let index = 0;
        while (index < val.length && val[index] === '0') {
            index++
        }
        return val.slice(index)
    }
}//obj
export default adi